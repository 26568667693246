import styled from "styled-components"
import { isMobile } from "react-device-detect"

export const Title = styled.h1`
  color: #b71430;
  font-size: 2.4em;
  margin: 0;
  text-shadow: 0 0 5px #ffffff, 1px 1px 1px #ffffff, -1px 1px 1px #ffffff,
    1px -1px 1px #ffffff, -1px -1px 1px #ffffff;
`

export const AppBody = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
`

export const Content = styled.p`
  padding: 20px 20px;
  max-width: 960px;
  text-align: center;
  color: #fff;
  hr {
    background-color: white;
    height: 5px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .location {
    display: flex;
    margin-bottom: 20px;
    flex-direction: ${isMobile ? "column" : "row"};
    align-items: center;
    span {
      margin-right: 15px;
    }
    p {
      font-size: 1.3em;
      text-align: ${isMobile ? "center" : "left"};
      margin-bottom: ${isMobile ? "20px" : "0px"};
    }
    * {
      width: 100%;
    }
    iframe {
      border: 1px solid black;
    }
  }
`

export const headerImageStyle = {
  backgroundSize: "cover",
  width: "100%",
  padding: "6%",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  maxHeight: "30vh",
  height: "400px",
  alignItems: "center",
  justifyContent: "center",
}

export const ContentContainer = styled.div`
  p {
    font-size: 0.9em;
    text-align: justify;
    color: #fff;
    img {
      width: 100%;
    }
  }
  h1 {
    padding-top: 30px;
    font-size: calc(18px + 2vmin);
    text-align: center;
  }
  form {
    background-color: #f8f8f8;
    padding: 20px 30px;
    border: 3px solid #000099;
    div {
      padding: 0px;
      margin-bottom: 20px;
      p {
        margin: 0px 0px 5px;
        label {
          font-weight: bold;
          font-size: 0.8em;
          color: black;
        }
      }
      input {
        border: 1px solid #000099;
        background-color: #fff;
        border-radius: 3px;
      }
      * {
        width: 100%;
      }
    }
    p {
      color: black;
    }
    button {
      background-color: #000099;
      color: white;
      font-weight: bold;
      padding: 10px 20px;
      border: 2px solid white;
      border-radius: 8px;
    }
  }
`
