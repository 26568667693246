import React, { useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BkgImg from "gatsby-background-image"
import { AppBody, Title, Content } from "../components/common"
import { isMobile } from "react-device-detect"
import { borderColor } from "@material-ui/system";

const Page = ({ data: { headerImage, logo } }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <AppBody>
        <div
          className="parallax"
          style={{
            backgroundSize: "cover",
            width: "100%",
            padding: "6%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Img
            fluid={logo.childImageSharp.fluid}
            style={{
              alignItems: "center",
              width: "50%",
              minWidth: "400px",
              alignSelf: "center",
            }}
            alt="plains dedicated"
          />
          <Title>America’s Transportation Providers</Title>
        </div>
        <Content>
          <b>Plains Dedicated LLC</b> started in 2007, and has grown and
          developed into a thriving nationwide line haul Co-Op.
          <br />
          <br />
          We pride ourselves in maintaining and developing customer
          relationships by standing behind our commitment and setting ourselves
          to a higher standard of service.
        </Content>
        <Cards>
          <Paper style={styles.Paper}>
            <PaperHeader>Corporate Office</PaperHeader>
            <PaperText>
              8390 ChampionsGate Blvd Suite 215 <br />
              ChampionsGate, FL 33896
              <br />
              (720) 259-7140
              <br />
              info@plainsdedicated.com
            </PaperText>
          </Paper>
          {/* <Paper style={styles.Paper}>
            <PaperHeader>Missouri Terminal</PaperHeader>
            <PaperText>
              201 South 1st Street
              <br />
              Sheridan, MO 64486
              <br />
              (660) 799-2226 Main
              <br />
              info@plainsdedicated.com
            </PaperText>
          </Paper> */}
          <Paper style={styles.PaperFacebook}>
            <PaperHeader style = {{color:"#fff"}}>Our Facebook Page</PaperHeader>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPlainsdedicatedllc&height=350&width=450&tabs=timeline&small_header=true&adapt_container_width=false&hide_cover=true&show_facepile=true&appId"
              height="350"
              width="450"
              style={{
                border: "none",
                overflow: "hidden",
                width: "auto",
                alignContent: "center",
              }}
              scrolling="no"
              frameBorder="0"
              allowTransparency={true}
              allow="encrypted-media"
            ></iframe>
          </Paper>
        </Cards>
        <Content></Content>
      </AppBody>
    </Layout>
  )
}

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logos/pd.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "overlay.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Page

const styles = {
  Paper: {
    textAlign: "center" as "center",
    alignItems: "left",
    margin: "10px",
    padding: "10px",
    width: "400px",
    maxWidth: "90vw",
    minWidth: "30vw",
    // background: "linear-gradient(55deg, #0001f8 0%,  #000098 65%)",
    background: "white",
    border: "solid",
    borderColor:"#b81430",
    borderRadius: "15px"
  },

  GetStarted: {
    width: "auto",
    textDecoration: "none",
    borderRadius: "10px",
    backgroundColor: "#000099",
    border: "none",
    padding: "calc(10px + 0.5vh) calc(15px + 2vw)",
    fontSize: "calc(18px + 2vw)",
    color: "white",
    fontWeight: "bold" as "bold",
  },
  BkgImg: {
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    padding: "6%",
    textAlign: "center" as "center",
    display: "flex",
    flexDirection: "column",
  },
  PaperFacebook: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    textAlign: "center" as "center",
    alignItems: "center",
    margin: "10px",
    padding: "10px",
    width: "60%",
  },
}

const PaperHeader = styled.h3`
  font-size: calc(10px + 2vw);
  margin-block-start: 2rem;
  margin-block-end: 2rem;
  color: #b81430;  

`

const PaperText = styled.p`
  font-size: calc(8px + 1vw);
  color: #b81430;
  text-align: center;
  justify-content: center;
  margin: 2rem;
  padding-top: 5px;
  padding-bottom: 10px;

`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1vh 5vw;
`
